import { HttpBackend, HttpClient } from "@angular/common/http";
import { inject, Injectable, isDevMode, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ASYNC_STATE, TAsyncState } from "@app/pages/task/+data-access";
import { IUser, IUserLogin } from "@app/shared/interfaces";
import { LogService } from "@app/shared/services";
import { LocalStorageApi } from "@app/shared/services/local-storage.service";
import { environment } from "@environments/environment";
import { ToastrService } from "ngx-toastr";
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  retry,
} from "rxjs";
import { ROAM_TOASTR } from "../const/app-roam.const";
import { AuthUser } from "./auth-user";
import { PushNotificationStore } from "../firebase/firebase.store";

const redirectUrlByRole: Record<string, string> = {
  tenant: "/home",
  owner: "/home",
};

@Injectable({ providedIn: "root" })
export class AuthStore {
  protected http: HttpClient;
  #pushNotificationStore = inject(PushNotificationStore);
  #apiUrl = environment.apiUrl;
  #localStorageApi = inject(LocalStorageApi);
  #activatedRoute = inject(ActivatedRoute);
  #logService = inject(LogService);
  #toastr = inject(ToastrService);

  #user = new BehaviorSubject<AuthUser | null>(this.initialUser);
  #error = new BehaviorSubject<Error | null>(null);
  #asyncState = new BehaviorSubject<TAsyncState>(ASYNC_STATE.IDLE);
  #redirectUrlConfig = signal(redirectUrlByRole);

  readonly redirectUrlConfig = this.#redirectUrlConfig.asReadonly();

  private get initialUser() {
    return this.#localStorageApi.getItem<AuthUser>("user");
  }

  get defaultRedirectUrl() {
    return "/overview";
  }

  setRedirectUrl = (v: Record<string, string>) => {
    this.#redirectUrlConfig.set(v);
  };

  getReturnUrl = (role?: string) => {
    return role ?
        this.redirectUrlConfig()[role] || this.defaultRedirectUrl
      : this.defaultRedirectUrl;
  };

  readonly user$ = this.#user.asObservable();
  readonly error$ = this.#error.asObservable();
  readonly asyncState$ = this.#asyncState.asObservable();
  readonly isLoading$ = this.asyncState$.pipe(map(x => x === "loading"));
  readonly isSubmitting$ = this.asyncState$.pipe(map(x => x === "submitting"));
  readonly hasSubmitted$ = this.asyncState$.pipe(map(x => x === "submitted"));
  readonly accessToken$ = this.user$.pipe(map(u => u?.token));
  readonly isAuthenticated$ = this.accessToken$.pipe(
    map(Boolean),
    distinctUntilChanged()
  );

  getAccessToken = () => {
    return this.getAuthUser().token;
  };

  isAuthenticated = () => {
    return Boolean(this.getAccessToken());
  };

  getAuthUser = () => {
    const user = this.#user.getValue();
    if (!user) {
      const msg = "AuthStore: User is not yet initialized!";
      this.#error.next(new Error(msg));
      throw new Error(msg);
    }
    return user;
  };

  setAuthUser = (user: AuthUser | null) => {
    this.#localStorageApi.setItem("user", user);
    this.#user.next(user);
  };

  patchAuthUser = (changes: Partial<AuthUser>) => {
    const user = this.getAuthUser();
    if (!user) return;
    this.setAuthUser({ ...user, ...changes });
  };

  getUserRole = () => {
    return this.getAuthUser().role;
  };

  getBoardUser = () => {
    return this.getAuthUser().boardUser;
  };

  getAdminUser = () => {
    return this.getAuthUser().groupAdmin;
  };

  getCustomer = () => {
    return this.getAuthUser().customers;
  };

  getError = () => {
    return this.#error.getValue();
  };

  propertyId = () => {
    return this.getAuthUser().customers?.[0]?.units?.[0]?.propertyId;
  }

  unitCustomer = () => {
    return this.getAuthUser().customers?.[0]?.units;
  }

  timeZone = () => {
    return this.getAuthUser().timezone;
  }

  #afterLoginEffect = () => {
    const user = this.getAuthUser();
    this.#asyncState.next("loaded");
    this.#logService.identify(user.id);
    const url =
      this.#activatedRoute.snapshot.queryParams["returnUrl"] ||
      this.getReturnUrl(user.role);
    location.replace(url);
  };

  localLogin(email: string, password: string, language = "en"): void {
    const url = `${environment.apiUrl}/users/login`;
    const body = { email, password, language };
    this.#asyncState.next("submitting");
    this.http.post<IUserLogin>(url, body).subscribe({
      next: resp => {
        // console.warn(resp);
        this.setAuthUser(resp as unknown as AuthUser);
        setTimeout(() => {
          this.#asyncState.next("submitted");
          // TODO: inject translation store!
          // this.setTranslations(resp.translations);
          this.#afterLoginEffect();
        }, 1000)
      },
      error: e => {
        this.setAuthUser(null);
        this.#asyncState.next("error");
        this.#error.next(e);
        this.#toastr.error(
          "Invalid username or password",
          "",
          ROAM_TOASTR.center
        );
      },
    });
  }

  loginViaUrlToken(tokenFromParam: string): void {
    type Resp = { token: string };
    const url = `${this.#apiUrl}/users/landing-verify/${tokenFromParam}`;
    this.#asyncState.next("submitting");
    this.http.get<Resp>(url).subscribe({
      next: resp => {
        this.setAuthUser(resp as unknown as AuthUser);
        this.#asyncState.next("submitted");
        const url = this.getReturnUrl(this.getAuthUser().role);
        location.replace(url);
      },
      error: () => {
        this.setAuthUser(null);
        this.#asyncState.next("error");
        this.#error.next(new Error("Invalid username or password!"));
      },
    });
  }

  logout(): void {
    this.#pushNotificationStore.uninstall().then(() => {
      this.setAuthUser(null);
      location.replace("/");
    })
  }

  #initUserPofile(accessToken: string): void {
    const url = `${this.#apiUrl}/me`;
    this.#asyncState.next("loading");

    this.#pushNotificationStore.install({
      accessToken
    });
    
    this.http
      .get<{ user: IUser }>(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .pipe(retry(3))
      .subscribe({
        next: resp => {
          this.patchAuthUser(resp.user);
        },
        error: e => {
          this.setAuthUser(null);
          this.#asyncState.next("error");
          this.#error.next(e);
        },
      });
  }

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
    this.accessToken$
      .pipe(filter(Boolean), distinctUntilChanged())
      .subscribe(token => {
        isDevMode() && console.warn("access token set/changed", token);
        this.#initUserPofile(token);
      });
  }
}
